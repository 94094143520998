@import '../../shared-styles/lib';

.separator {
  background-image: url('../../../public/assets/images/game-images/seperator.png');
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;

  height: 60px;

  margin-top: calc-vertical-margin($vertical-margin-medium);

  @include for-phone-only {
    background-size: 100%;
    height: 40px;
  }
}
