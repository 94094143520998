@import '../../shared-styles/lib';

.draft-civ {
  display: grid;
  grid-template-rows: 1fr 1fr;
  @include grid-alignment-center;

  @include for-phone-only {
    height: 220px;
    align-items: baseline;
  }

  @include for-tablet-portrait-up {
    height: 260px;
  }

  @include for-4k-up {
    height: 310px;
  }
}
