@import '../../shared-styles/lib';

.save-civ-pool-button-container {
  // really strange vertical space rendering in this component
  // it clips the top border by 13px, not sure why, but padding
  // works better than margin which is completely ignored
  padding-top: calc-padding($padding-medium);
  padding-bottom: calc-padding($padding-large);

  margin-bottom: calc-vertical-margin($vertical-margin-medium);

  text-align: center;

  .save-civ-pool-button {
    @include button-base(filled-dark);

    &:active {
      border: $border-thickness-tiny solid $primary-color-amber-dark;
    }

    @include for-tablet-portrait-up {
      width: 80%;
    }
  }
}
