@import './color';
@import './border';
@import './box-shadow';

$button-letter-color: $primary-color-amber;
$button-letter-color-active: $primary-color-amber-dark;
$button-letter-color-hover: $primary-color-amber-light;

$button-inverse-letter-color: $black;

$button-filled-primary-bg-color: $primary-color-red;
$button-filled-primary-bg-color-active: $primary-color-red-dark;
$button-filled-bg-color-hover: $primary-color-red-light;

$button-filled-inverse-bg-color: $primary-color-amber;
$button-filled-inverse-bg-color-active: $primary-color-amber-dark;
$button-filled-bg-color-hover: $primary-color-amber-light;

$button-filled-dark-bg-color: $black;
$button-filled-dark-bg-color-active: $black;
$button-filled-dark-bg-color-hover: #0b0b0b;

$button-filled-light-bg-color: $bg-color-lightest;
$button-filled-light-bg-color-active: #191925;

$button-outline-bg-color-active: #191925;
$button-outline-bg-color-hover: #282845;

$button-border-color: $button-letter-color;

@mixin button-base(
  $type: outline,
  $padding: $padding-medium,
  $box-shadow: true,
  $box-shadow-size: large
) {
  @if $box-shadow {
    @include box-shadow($box-shadow-size);
  }

  cursor: pointer;
  padding: calc-padding($padding);

  text-align: center;
  border-radius: $border-radius-medium;

  @if $type == filled-primary {
    background-color: $button-filled-primary-bg-color;
    color: $button-letter-color;
  } @else if $type == filled-inverse {
    background-color: $button-filled-inverse-bg-color;
    color: $button-inverse-letter-color;
  } @else if $type == filled-dark {
    background-color: $button-filled-dark-bg-color;
    color: $button-letter-color;
  } @else if $type == filled-light {
    background-color: $button-filled-light-bg-color;
    color: $button-letter-color;
  } @else if $type == outline {
    border: $border-thickness-tiny solid $button-border-color;
    color: $button-letter-color;
  }

  @if $type == filled-primary or $type == filled-inverse or $type == filled-dark
  {
    &:hover {
      transform: scale(1.01);
    }
  } @else if $type == outline or $type == text {
    &:hover {
      background-color: $button-outline-bg-color-hover;
    }
  }

  &:active {
    @if $type == filled-primary {
      background-color: $button-filled-primary-bg-color-active;
    } @else if $type == filled-inverse {
      background-color: $button-filled-inverse-bg-color-active;
    } @else if $type == filled-dark {
      background-color: $button-filled-dark-bg-color-active;
      border: $border-thickness-tiny solid $button-border-color;
    } @else if $type == filled-light {
      background-color: $button-filled-light-bg-color-active;
      border: $border-thickness-tiny solid $button-border-color;
    } @else {
      background-color: $button-outline-bg-color-active;
    }
  }
}
