@import '../../shared-styles/lib';

.loading-container {
  width: max-content;

  margin-left: auto;
  margin-right: auto;

  text-align: center;
}

.loading-text {
  font-size: calc-font-size($font-size-16);
}

.loading-video {
  height: 200px;

  border-radius: $border-radius-medium;
}
