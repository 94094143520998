@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-1080p-up {
  @media (min-width: 1900px) {
    @content;
  }
}

@mixin for-4k-up {
  @media (min-width: 2000px) {
    @content;
  }
}
