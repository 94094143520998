@import '../../../shared-styles/lib';
@import '../tech-tree-filter';

.matching-civs-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.matching-civs-header {
  background-color: $bg-color-light;
  border-radius: $border-radius-medium;
  margin-top: calc-vertical-margin($vertical-margin-medium);
  margin-bottom: calc-vertical-margin($vertical-margin-medium);
  padding-top: calc-padding($padding-medium);
  padding-bottom: calc-padding($padding-medium);
}

.matching-civs-stats {
  text-align: center;

  .matched-civs-number {
    color: $primary-color-amber;
  }
}

.buttons-container {
  flex: 1 1 auto;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include grid-alignment-center;

  margin-top: calc-vertical-margin($vertical-margin-medium);

  text-align: center;

  @include for-phone-only {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;

    font-size: calc-font-size($font-size-8);
  }

  @include for-tablet-portrait-up {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;

    font-size: calc-font-size($font-size-4);
  }

  @include for-tablet-landscape-up {
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    font-size: calc-font-size($font-size-6);
  }

  @include for-4k-up {
    font-size: calc-font-size($font-size-8);
  }
}

.replace-main-civ-pool-button,
.add-to-main-civ-pool-button,
.subtract-from-main-civ-pool-button {
  width: 90%;
  @include button-base(
    filled-inverse,
    $padding: $padding-small,
    $box-shadow-size: small
  );
}

.loading-wrapper {
  height: 100%;

  background-color: $bg-color-light;
  border-radius: $border-radius-medium;
}

.matching-civs {
  flex: 1 1 80%;

  display: grid;

  padding-bottom: calc-padding($padding-large);

  background-color: $bg-color-light;
  border-radius: $border-radius-medium;

  overflow-y: auto;

  @include for-phone-only {
    grid-template-columns: repeat(5, 1fr);
  }

  @include for-tablet-portrait-up {
    grid-template-columns: repeat(2, 1fr);
  }

  @include for-tablet-landscape-up {
    grid-template-columns: repeat(3, 1fr);
  }

  @include for-1080p-up {
    grid-template-columns: repeat(5, 1fr);
  }

  @include for-4k-up {
    grid-template-columns: repeat(6, 1fr);
  }
}
