$white: #fff;
$grey: #888;
$black: #000;
$transparent: rgba(0, 0, 0, 0%);

$bg-color: #161620;
$bg-color-light: #202030;
$bg-color-lightest: #252540;

$primary-color-amber: #ffca28;
$primary-color-amber-dark: #c79a00;
$primary-color-amber-light: #fffd61;
$primary-color-amber-text: $black;

$primary-color-red: #7f0000;
$primary-color-red-dark: #5f0000;
$primary-color-red-light: #9a0d0d;
$primary-color-red-text: $white;
