@import '../../shared-styles/lib';

.civ-pool-title,
.civ-pool-tip {
  text-align: center;
}

.civ-pool-title {
  margin-top: calc-vertical-margin($vertical-margin-small);
}

.civ-pool-tip {
  margin-top: -20px;

  .civ-pool-count {
    color: $primary-color-amber;
  }
}

.civ-pool {
  display: grid;
  @include grid-alignment-center;

  @include for-phone-only {
    grid-template-columns: repeat(5, 1fr);
  }

  @include for-tablet-portrait-up {
    grid-template-columns: repeat(7, 1fr);
  }

  @include for-tablet-landscape-up {
    grid-template-columns: repeat(9, 1fr);
  }

  @include for-1080p-up {
    grid-template-columns: repeat(12, 1fr);
    width: 90vw;
    margin: auto;
  }

  @include for-4k-up {
    grid-template-columns: repeat(14, 1fr);
    width: 80vw;
    margin: auto;
  }
}
