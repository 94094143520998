$transition-props: border background box-shadow transform;

@keyframes highlight-drafted-civ {
  from {
    opacity: 0.1;
    transform: translateY(30px) scale(1);
  }

  to {
    opacity: 1;
    transform: translateY(0px) scale(1.3);
  }
}

@keyframes highlight-drafted-civ-mobile {
  from {
    opacity: 0.1;
    transform: translateY(30px) scale(1);
  }

  to {
    opacity: 1;
    transform: translateY(0px) scale(2);
  }
}
