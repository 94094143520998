@import '../../shared-styles/lib';

footer {
  .credits-title {
    margin-top: 0;
    text-align: center;
  }

  .credits-text {
    width: 80%;

    margin-left: auto;
    margin-right: auto;

    font-size: calc-font-size($font-size-4);
    text-align: center;

    @include for-phone-only {
      font-size: calc-font-size($font-size-base);
    }
  }

  a {
    text-decoration: underline;
    color: $primary-color-amber;
  }
}
