@import '../../shared-styles/lib';

.top-app-bar {
  display: grid;
  grid-template-columns: 1fr;
  @include grid-alignment-center;

  margin-top: calc-vertical-margin($vertical-margin-medium);
}

.logo-container {
  width: max-content;
}

.logo {
  img {
    display: block;
    width: 50%;
    height: 50%;
    margin: auto;
  }
}

.app-title {
  margin-top: calc-vertical-margin($vertical-margin-medium);

  color: $primary-color-amber;

  font-size: calc-font-size($font-size-14);
  font-variant: small-caps;
  text-align: center;
}

.game-version {
  margin-top: calc-vertical-margin($vertical-margin-small);
  margin-bottom: calc-vertical-margin($vertical-margin-small);

  font-size: calc-font-size($font-size-6);
  font-weight: 300;
  text-align: center;

  @include for-4k-up {
    font-size: calc-font-size($font-size-4);
  }
}
