$border-neutral-color: $grey;
$border-primary-color-amber: $primary-color-amber-dark;
$border-primary-color-red: $primary-color-red-dark;

$border-thickness-tiny: 1px;
$border-thickness-small: 2px;
$border-thickness-medium: 3px;

$border-radius-tiny: 2px;
$border-radius-small: 4px;
$border-radius-medium: 8px;
$border-radius-large: 16px;
